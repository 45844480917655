import React from "react";
import easBg from "../../assets/easBg.png";
import "./styles.sass";

const ArticleHero = (props) => {
  return (
    <section className='articleHero'>
      <div className='articleHeroImage'>
        <img
          src={easBg}
          alt='easBg'
          className='img'
        />
      </div>
      <div className='backdropFilter'></div>
      <div className='articleHeroContent'>
        <div className='container'>
          <div className='articleHeroTitle'>
            <h3 className='title'>{props?.newArticleData?.title}</h3>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ArticleHero;
