import React from "react";
import "./styles.sass";

const ArticleContent = (props) => {
  return (
    <section className='articleContent'>
      <div className='container'>
        <div className='articleContainer'>
          <div className='contentImage'>
            <img
              src={props?.newArticleData?.image}
              alt='articleContentImage'
              className='img'
            />
          </div>
          <div className='contentItems'>
            <div className='contentTitle'>
              <h3 className='title'>{props?.newArticleData?.title}</h3>
            </div>
            <div className='contentText'>
              <p className='text'>{props?.newArticleData?.text}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ArticleContent;
