import newsImage from "../src/assets/newsImageEas.JPG";
import productOne from '../src/assets/23.jpg'
import productTwo from '../src/assets/19.jpg'
import productImageOne from '../src/assets/21.jpg'
import productImageTwo from '../src/assets/18.jpg'
import productImageThree from '../src/assets/20.jpg'
import productImageFour from '../src/assets/25.jpg'
import productImageFive from '../src/assets/27.jpg'
import productImageSix from '../src/assets/9.jpg'
import productImageSeven from '../src/assets/11 (1).jpg'
import productImageEight from '../src/assets/13.jpg'
import productImageNine from '../src/assets/15.jpg'
import productImageTen from '../src/assets/16.jpg'
import productImageEleven from '../src/assets/14.jpg'
import productImageTwelve from '../src/assets/12.jpg'
import productImageTirteen from '../src/assets/car.png'

export const productData = [
    {
        id: 1,
        title: 'EAS-ADS "AIR SHIELD-1C',
        text: {
            en: "Detection, direction finding and electronic frequency blocking of control channels, data transmission and navigation of drones (small UAVs)",
            ru: "Обнаружение, пеленгация и радиоэлектронная блокировка частот каналов управления, передачи данных и навигации дронов (малоразмерными БПЛА)",
            uz: "Boshqarish kanallarini aniqlash, yo'nalishni aniqlash va elektron chastotalarni blokirovka qilish, ma'lumotlarni uzatish va dronlarni navigatsiya qilish (kichik UAVlar)"
        },
        image: productOne,
        images: [
            productImageOne,
            productImageOne,
            productImageOne,
            productImageOne,
            productImageOne,
            productImageOne,
        ],
        description: [
            {id: 1, 
                title: {
                    ru: "Главные характеристики",
                    en: "Main characteristics",
                    uz: "Asosiy xususiyatlar"
                }, 
                text: {
                    ru: [
                        'Температура +70С',
                        'Масса: 12кг',
                        'Жизнынней цикл батареи: 40',
                        'Дальность подавления: +2км',
                        'Рабочая частота: 433MHz, 900MHz, 1.5GHz, 5.2GHz, 1.2GHz, 2.4GHz, 5.8GHz'
                    ],
                    en: [

                        'Temperature +70С',
                        'Weight: 12kg',
                        'Battery life: 40',
                        'Radio jamming range: +2km',
                        'Operating frequency: 433MHz, 900MHz, 1.5GHz, 5.2GHz, 1.2GHz, 2.4GHz, 5.8GHz'
                    ],
                    uz: [

                        'Temperaturasi +70С',
                        "Og'irligi: 12kg",
                        'Batareyaning ish vaqti: 40',
                        'Radiobosim berish uzoqligi: +2km',
                        'Ishlash chastotasi: 433MHz, 900MHz, 1.5GHz, 5.2GHz, 1.2GHz, 2.4GHz, 5.8GHz'
                    ]
                },
            },  
        ]
    },
    {
        id: 2,
        title: 'EAS-ADS "AIR SHIELD-1C',
        text: {
            en: "Detection, direction finding and electronic frequency blocking of control channels, data transmission and navigation of drones (small UAVs)",
            ru: "Обнаружение, пеленгация и радиоэлектронная блокировка частот каналов управления, передачи данных и навигации дронов (малоразмерными БПЛА)",
            uz: "Boshqarish kanallarini aniqlash, yo'nalishni aniqlash va elektron chastotalarni blokirovka qilish, ma'lumotlarni uzatish va dronlarni navigatsiya qilish (kichik UAVlar)"
        },
        image: productTwo,
        images: [
            productTwo,
            productImageTwo,
            productImageThree,
            productTwo,
            productImageTwo,
            productImageThree,
        ],
        description:[
            {id: 1, title: {
                ru: "Главные характеристики",
                en: "Main characteristics",
                uz: "Asosiy xususiyatlar"
            }, text: {
                ru: [
                    'Температура -25C; +70С',
                    'Масса: 9.5кг',
                    'Жизнынней цикл батареи: 40',
                    'Дальность подавления: +3км',
                    'Рабочая частота: 70-6000MHz'
                ],
                en: [
                    'Temperature -25C; +70С',
                    'Weight:  9.5kg',
                    'Battery life: 40',
                    'Radio jamming range: +3km',
                    'Operating frequency: 70-6000MHz'
                ],
                uz: [
                    'Temperaturasi -25C; +70С',
                    "Og'irligi: 9.5kg",
                    'Batareyaning ish vaqti: 40',
                    'Radiobosim berish uzoqligi: +3km',
                    'Ishlash chastotasi: 70-6000MHz'
                ]
            }},
        ]
    },
    {
        id: 3,
        title: 'EAS-ADE-2 "AIRWALL',
        text: {
            en: "Detection, direction finding and electronic frequency blocking of control channels, data transmission and navigation of drones (small UAVs)",
            ru: "Обнаружение, пеленгация и радиоэлектронная блокировка частот каналов управления, передачи данных и навигации дронов (малоразмерными БПЛА)",
            uz: "Boshqarish kanallarini aniqlash, yo'nalishni aniqlash va elektron chastotalarni blokirovka qilish, ma'lumotlarni uzatish va dronlarni navigatsiya qilish (kichik UAVlar)"
        },
        image: productImageFour,
        images: [
            productImageFive,
            productImageFour,
            productImageSix,
            productImageSeven,
        ],
        description:[
            {id: 1, title: {
                ru: "Главные характеристики",
                en: "Main characteristics",
                uz: "Asosiy xususiyatlar"
            }, text: {
                ru: [
                    'Радиус обнаружения: 360',
                    'Масса: 12кг',
                    'Температура: -30С; +70C',
                    'Разведывательный диапазон: +8км',
                    'Частота радиоподпвления: 400-6000MHz'
                ],
                en: [
                    'Detection radius: 360',
                    'Weight: 12kg',
                    'Temperature: -30С; +70C',
                    'Reconnaissance range: +8km',
                    'Frequency range: 400-6000MHz'
                ],
                uz: [
                    'Aniqlash radiusi: 360',
                    "Og'irligi: 12kg",
                    'Temperaturasi: -30С; +70C',
                    'Kuzatuv diapazoni: +8km',
                    'Radiobosim berish chastotasi: 400-6000MHz'
                ]
            }},
        ]
    },
    {
        id: 4,
        title: 'EAS-ADE-2 "AIRWALL',
        text: {
            en: "Detection, direction finding and electronic frequency blocking of control channels, data transmission and navigation of drones (small UAVs)",
            ru: "Обнаружение, пеленгация и радиоэлектронная блокировка частот каналов управления, передачи данных и навигации дронов (малоразмерными БПЛА)",
            uz: "Boshqarish kanallarini aniqlash, yo'nalishni aniqlash va elektron chastotalarni blokirovka qilish, ma'lumotlarni uzatish va dronlarni navigatsiya qilish (kichik UAVlar)"
        },
        image: productImageFour,
        images: [
            productImageFive,
            productImageFour,
            productImageSix,
            productImageSeven,
        ],
        description:[
            {id: 1, title: {
                ru: "Главные характеристики",
                en: "Main characteristics",
                uz: "Asosiy xususiyatlar"
            }, text: {
                ru: [
                    'Разрешение: 1920x1080',
                    'Масса: 83кг',
                    'Жизненный цикл батареи: 40',
                    'Рабочий диапазон: +3км',
                    'Рабочая частотавыходная мощность: 433MHz:50W, 1.2GHz:50W, 2.4GHz: 50W, 5.8GHz:100W, 90MHz: 50W, 1.5GHz: 50W, 5.2GHz: 100W, 5.8GHz: 100W'
                ],
                en: [
                    'Resolution: 1920x1080',
                    'Weight: 83kg',
                    'Battery life: 40',
                    'Operating range: +3km',
                    'Operating frequency and output power: 433MHz:50W, 1.2GHz:50W, 2.4GHz: 50W, 5.8GHz:100W, 90MHz: 50W, 1.5GHz: 50W, 5.2GHz: 100W, 5.8GHz: 100W'
                ],
                uz: [
                   'Ruxsat aniqligi: 1920x1080',
                    "Og'irligi: 83kg",
                    'Batareyaning ish vaqti: 40',
                    'Ishlash diapazoni: +3km',
                    'Chiqish quvvati: 433MHz:50W, 1.2GHz:50W, 2.4GHz: 50W, 5.8GHz:100W, 90MHz: 50W, 1.5GHz: 50W, 5.2GHz: 100W, 5.8GHz: 100W'
                ]
            }},
        ]
    },
    {
        id: 5,
        title: 'EAS-1 "FOWLER',
        text: {
            en: "Radio-electronic frequency blocking of control channels, data transmission and navigation of drones (small UAVs)",
            ru: "Радиоэлектронная блокировка частот каналов управления, передачи данных и навигации дронов (малоразмерными БПЛА)",
            uz: "Boshqarish kanallarini aniqlash, yo'nalishni aniqlash va elektron chastotalarni blokirovka qilish, ma'lumotlarni uzatish va dronlarni navigatsiya qilish (kichik UAVlar)"
        },
        image: productImageEight,
        images: [
            productImageNine,
            productImageEight,
            productImageEleven,
            productImageTwelve,
            productImageTen,
        ],
        description:[
            {id: 1, title: {
                ru: "Главные характеристики",
                en: "Main characteristics",
                uz: "Asosiy xususiyatlar"
            }, text: {
                ru: [
                    'Температура: -30С; +55C',
                    'Масса: 6.5кг',
                    'Жизненный цикл батареи: 40',
                    'Дальность подавления: +1.5км',
                    'Каналы подавления: 433MHz, 900MHz, 1.2GHz, 1.5GHz, 5.8GHz, 1.5GHz, 2.4GHz'
                ],
                en: [
                    'Temperature: -30С; +55C',
                    'Weight: 6.5kg',
                    'Battery life: 40',
                    'Radio jamming range: +1.5km',
                    'Supperssion Channels: 433MHz, 900MHz, 1.2GHz, 1.5GHz, 5.8GHz, 1.5GHz, 2.4GHz'
                ],
                uz: [
                    'Temperaturasi: -30С; +55C',
                    "Og'irligi: 6.5kg",
                    'Battereyaning ish vaqti: 40',
                    'Radiobosim berish uzoqligi: +1.5km',
                    'Bostirish kanallari: 433MHz, 900MHz, 1.2GHz, 1.5GHz, 5.8GHz, 1.5GHz, 2.4GHz'
                ]
            }},
        ]
    },
    {
        id: 6,
        title: 'EAS-2 "AIRFENDER',
        text: {
            en: "Radio-electronic frequency blocking of control channels, data transmission and navigation of drones (small UAVs)",
            ru: "Радиоэлектронная блокировка частот каналов управления, передачи данных и навигации дронов (малоразмерными БПЛА)",
            uz: "Boshqarish kanallarini aniqlash, yo'nalishni aniqlash va elektron chastotalarni blokirovka qilish, ma'lumotlarni uzatish va dronlarni navigatsiya qilish (kichik UAVlar)"
        },
        image: productImageEleven,
        images: [
            productImageNine,
            productImageEight,
            productImageEleven,
            productImageTwelve,
            productImageTen,
        ],
        description:[
            {id: 1, title: {
                ru: "Главные характеристики",
                en: "Main characteristics",
                uz: "Asosiy xususiyatlar"
            }, text: {
                ru: [
                    'Температура: -30С; +55C',
                    'Масса: 2.5кг',
                    'Жизненный цикл батареи: 40',
                    'Дальность подавления: +1км',
                    'Каналы подавления:  1.2GHz, 1.5GHz, 2.4GHz, 5.8GHz'
                ],
                en: [
                    'Temperature: -30С; +55C',
                    'Weight: 2.5kg',
                    'Battery life: 40',
                    'Radio jamming range: +1km',
                    'Supperssion Channels: 1.2GHz, 1.5GHz, 2.4GHz, 5.8GHz'
                ],
                uz: [
                    'Temperaturasi: -30С; +55C',
                    "Og'irligi: 2.5kg",
                    'Battereyaning ish vaqti: 40',
                    'Radiobosim berish uzoqligi: +1km',
                    'Bostirish kanallari: 1.2GHz, 1.5GHz, 2.4GHz, 5.8GHz'
                ]
            }},
        ]
    },
    {
        id: 7,
        title: 'EAS-ADS "AIR SHIELD - 1CM',
        text: {
            en: "The stationary system is designed for detection, direction finding, determination of flight direction and radio-electronic frequency blocking of control channels, data transmission and navigation of drones (small unmanned aerial vehicles).",
            ru: "Стационарная система предназначена для обнаружения, пеленгования, оприделение направления полета и радиоэлектроная блокировка частот каналов управления, перидачи данных и навигации дронов (малоразмерных беспилотных летатильных фпаратов).",
            uz: "Statsionar tizim aniqlash, yoʻnalishni aniqlash, parvoz yoʻnalishini aniqlash va boshqaruv kanallarini radioelektron chastotada blokirovka qilish, dronlarni (kichik uchuvchisiz uchish apparatlari) maʼlumotlarni uzatish va navigatsiya qilish uchun moʻljallangan."
        },
        image: productImageTirteen,
        images: [
            productImageTirteen,
            productImageTirteen,
            productImageTirteen,
            productImageTirteen,
            productImageTirteen,
        ],
        description:[
            {id: 1, title: {
                ru: "Главные характеристики",
                en: "Main characteristics",
                uz: "Asosiy xususiyatlar"
            }, text: {
                ru: [
                    'Стандарты надежности автомобилей',
                    'Полусферическая защита. Без защитных слепых зон',
                    'Одновременное обнаружение и подавления',
                    'Способность определять направление движение БПЛА',
                    'Интеграция автономного и ручного режимов'
                ],
                en: [
                    'Vehicle Reliability Standards',
                    'Hemispherical protection. No protective blind spots',
                    'Simultaneous detection and suppression',
                    'The ability to determine the direction of movement of a UAV',
                    'Integration of autonomous and manual modes'
                ],
                uz: [
                    'Avtomobil ishonchliligi standartlari',
                    "Yarim sharni himoya qilish. Himoya ko'r joylari yo'q",
                    "Bir vaqtning o'zida aniqlash va bostirish",
                    "UAV harakat yo'nalishini aniqlash qobiliyati",
                    "Avtonom va qo'lda rejimlarning integratsiyasi"
                ]
            }},
        ]
    },
]


export const newsData = [
    {
      id: 1,
      image: newsImage,
      title: {
        ru: 'Международная выставка securex 2024',
        en: 'International exhibition securex 2024',
        uz: "Securityx 2024 xalqaro ko'rgazmasi"
      },
      text: {
        ru: 'Выставка на которой мы сможем представить наш продукт для международных компаний. Будет проходить в Узьекистане в экспо центре. Ждите скорых подробностей.',
        en: 'An exhibition where we will be able to present our product to international companies. It will be held in Uzbekistan in the expo center. Wait for details soon.',
        uz: "Xalqaro kompaniyalarga mahsulotimizni taqdim eta oladigan ko'rgazma. U O‘zbekistonda ko‘rgazma markazida bo‘lib o‘tadi. Tez orada batafsil ma'lumot olish uchun obuna bo'ling."
      },
    },
    {
        id: 2,
        image: newsImage,
        title: {
          ru: 'Международная выставка securex 2024',
          en: 'International exhibition securex 2024',
          uz: "Securityx 2024 xalqaro ko'rgazmasi"
        },
        text: {
          ru: 'Выставка на которой мы сможем представить наш продукт для международных компаний. Будет проходить в Узьекистане в экспо центре. Ждите скорых подробностей.',
          en: 'An exhibition where we will be able to present our product to international companies. It will be held in Uzbekistan in the expo center. Wait for details soon.',
          uz: "Xalqaro kompaniyalarga mahsulotimizni taqdim eta oladigan ko'rgazma. U O‘zbekistonda ko‘rgazma markazida bo‘lib o‘tadi. Tez orada batafsil ma'lumot olish uchun obuna bo'ling."
        },
      },
      {
        id: 3,
        image: newsImage,
        title: {
          ru: 'Международная выставка securex 2024',
          en: 'International exhibition securex 2024',
          uz: "Securityx 2024 xalqaro ko'rgazmasi"
        },
        text: {
          ru: 'Выставка на которой мы сможем представить наш продукт для международных компаний. Будет проходить в Узьекистане в экспо центре. Ждите скорых подробностей.',
          en: 'An exhibition where we will be able to present our product to international companies. It will be held in Uzbekistan in the expo center. Wait for details soon.',
          uz: "Xalqaro kompaniyalarga mahsulotimizni taqdim eta oladigan ko'rgazma. U O‘zbekistonda ko‘rgazma markazida bo‘lib o‘tadi. Tez orada batafsil ma'lumot olish uchun obuna bo'ling."
        },
      },
      {
        id: 4,
        image: newsImage,
        title: {
          ru: 'Международная выставка securex 2024',
          en: 'International exhibition securex 2024',
          uz: "Securityx 2024 xalqaro ko'rgazmasi"
        },
        text: {
          ru: 'Выставка на которой мы сможем представить наш продукт для международных компаний. Будет проходить в Узьекистане в экспо центре. Ждите скорых подробностей.',
          en: 'An exhibition where we will be able to present our product to international companies. It will be held in Uzbekistan in the expo center. Wait for details soon.',
          uz: "Xalqaro kompaniyalarga mahsulotimizni taqdim eta oladigan ko'rgazma. U O‘zbekistonda ko‘rgazma markazida bo‘lib o‘tadi. Tez orada batafsil ma'lumot olish uchun obuna bo'ling."
        },
      },
      {
        id: 5,
        image: newsImage,
        title: {
          ru: 'Международная выставка securex 2024',
          en: 'International exhibition securex 2024',
          uz: "Securityx 2024 xalqaro ko'rgazmasi"
        },
        text: {
          ru: 'Выставка на которой мы сможем представить наш продукт для международных компаний. Будет проходить в Узьекистане в экспо центре. Ждите скорых подробностей.',
          en: 'An exhibition where we will be able to present our product to international companies. It will be held in Uzbekistan in the expo center. Wait for details soon.',
          uz: "Xalqaro kompaniyalarga mahsulotimizni taqdim eta oladigan ko'rgazma. U O‘zbekistonda ko‘rgazma markazida bo‘lib o‘tadi. Tez orada batafsil ma'lumot olish uchun obuna bo'ling."
        },
      },
  ];